
export default function Card({project, openModal}) {
    const cardStyle = {
        backgroundImage: `url(${project.cover})`,
    };
    
    return (
        <article className='card' style={cardStyle} onClick={()=> openModal(project)}>
          <div className="card__content">
              <div className='card__info'>
                 <h1 className='card__title'>{project.title}</h1>
                 <h2 className='card__text'>{project.text}</h2>
                 <span className='card__tags'>
                   {project.tags.map((tag, index) => (
                    <button className="tag" key={index}>{tag}</button> 
                   ))}
                 </span>
              </div>  
          </div>
              
        </article>

    )

}


