import { useState } from 'react';
import projectsList from '../data/projects';


export default function Filter({onCatSelect}) {

    const categories = ['Tous',...new Set(projectsList.map((project) => project.category))];
    const [selectedCategory, setCategory] = useState('Tous');

    const handleClick =(cat) => {
        setCategory(cat)
        onCatSelect(cat);
    }

    return (
        <div className='categories'>
            {categories.map((cat, id) => {
                return ( 
                    <button className={`filter__btn ${selectedCategory === cat ? 'active' : ''}`} 
                    key={id} onClick={() => handleClick(cat)}>{cat}</button>
                );
            })}
           
       </div> 

    )
}
