
import htmlLogo from '../media/logos/html_logo.png';
import cssLogo from '../media/logos/css_logo.png';
import jsLogo from '../media/logos/js_logo.png';
import reactLogo from '../media/logos/react_logo.png';
import nodeLogo from '../media/logos/node_logo.png';
import mongoLogo from '../media/logos/mongo_logo.png';

const skillsList = [
    { id: "1", title: "HTML5 & CSS", 
    imgUrl: htmlLogo, 
    description: [
        "Structuration de pages web modernes et accessibles.",
        "Mise en page responsive avec Flexbox.",
    ]
    },
    { id: "2",
     title: "CSS",
    imgUrl: cssLogo, 
    description: "Stylisation soignée pour une expérience utilisateur fluide." 
    },
    { id: "3",
     title: "JAVASCRIPT",
    imgUrl: jsLogo,
     description: [
     "Programmation moderne avec classes, modules, promises.", 
     "Manipulation de données et gestion d’événements.",
     "Intégration d’APIs et gestion des requêtes asynchrones."
     ]
    },
    { id: "4",
     title: "REACT",
     imgUrl: reactLogo,
     description: [
        "Création d’interfaces dynamiques avec des composants",
        "Gestion de l’état via hooks",
        "Navigation avec React Router"
     ]
    },
    { id: "5", 
    title: "NODE", 
    imgUrl: nodeLogo, 
    description: [
        "Création de serveurs backend avec Express.js",
        "Mise en place d’APIs RESTful et gestion des requêtes.",
        "Authentification et sécurisation avec JWT." 
     ]
    },
    { id: "6", 
    title: "MONGODB", 
    imgUrl: mongoLogo, 
    description: [
        "Conception de bases de données NoSQL évolutives.",
        "Opérations de lecture/écriture avec Mongoose.",
        "Gestion de données utilisateurs"
    ]    
   }
];

export default skillsList;
