
const projectsList = [
    { id: "1", 
    title: "Booki",
    cover: require('../media/covers/booki.webp'), 
    text: "Création d'un page d'une agence de voyage avec HTML & CSS",
    description: "Un site qui permet aux utilisateur.ices de trouver des hébergements et des activités dans la ville de leur choix.",
    difficulties: "Une première implémentation, nécessitant de mettre en place de nouvelles connaissances. ",
    category: 'Intégration front-end',
    tags: [ 'HTML & CSS', 'Front-end', 'Responsive',],
    skills: "Implémentation d\'une interface responsive avec HTML et CSS", 
    github: "https://github.com/kimtr9/Booki"
    },
    { id: "2", 
    title: "Portfolio de Sophie Bluel", 
    cover: require('../media/covers/sophiebluel.webp'), 
    text: "Création d'une page web dynamique avec Javascript",
    description: 'Implémentation du front-end d\'un site d\'architecte d\'intérieur en utilisant JavaScript.',
    difficulties: "Découverte de Javascript, de sa syntaxe et sa structure, de la gestion du DOM... ",
    category: 'Intégration front-end',
    tags: [ 'HTML & CSS','Javascript', 'Gestion de données', 'Responsive'],
    skills: [ "Gestion des événements utilisateurs avec JavaScript", "Manipulation des éléments du DOM avec JavaScript",
    "Récupération des données utilisateurs dans le JavaScript via des formulaires" ],
    github: "https://github.com/kimtr9/P3-Sophie-Bluel"
    },
    { id: "3", 
    title: "Nina Carducci", 
    cover: require('../media/covers/nina.webp'), 
    text: "Optimisation & déboggage d'un site",
    description: 'Un site d\'une photographe avec une gestion des médias et un référencement à optimiser',
    difficulties: "Un aspect du développement intéressant sur lequel je n'avais jamais encore travaillé ",
    category: 'SEO',
    tags: [ 'SEO', 'DevTools', 'LightHouse'],
    skills: [ "Optimisation des performances et de l'accessibilité du site",
        "Déboggage grâce aux Chrome DevTools",
        "Rédaction d'un cahier de recette pour tester le site" ],
    github: "https://github.com/kimtr9/P4-Nina-Carducci"
    },
    { id: "4", 
    title: "Kasa",
    cover: require('../media/covers/kasa.webp'), 
    text: "Développement d'une application web de location immobilière",
    description: 'Implémentation du front-end du site, avec React.',
    difficulties: "Découverte des composants, des hooks et de l'utilisation du JSX ",
    category: 'Intégration front-end',
    tags: ['React', 'Sass', 'Node.js'],
    skills: [ "Implémentation d'interface web en React & Sass",
        "Développement des éléments avec composants React",
        "Stylisation avec Sass" ],
    github: "https://github.com/kimtr9/P5-Kasa-site"
    },
    { id: "5", 
    title: "Mon vieux grimoire",
    cover: require('../media/covers/vieuxgrimoire.webp'), 
    text: "Développement du back-end d'un site de notation de livres",
    description: 'Implémentation du back-end du site, avec gestion sécurisée des données .',
    difficulties: "Découverte complète du back-end et de son fonctionnement. ",
    category: 'Intégration back-end',
    tags: [ 'Node.js', 'MongoDB', 'CRUD'],
    skills: [ "Implémentation d'un modèle logique de données conformément à la réglementation",
        "Mise en œuvre des opérations CRUD de manière sécurisée",
        "Stockage des données de manière sécurisée" ],
    github: "https://github.com/kimtr9/P5-Kasa-site"
    },

]

export default projectsList;