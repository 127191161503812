import { Link } from "react-router-dom"
export default function NoPage() {
    return (
        <div className="error__page">
            <h1 className="error__title">ERREUR 404 </h1>
            <h2 className="error__message">Oops, une erreur s'est glissée par ici :/ </h2>

             <Link className="error__link" to= '/'> Retour à l'accueil</Link>
        </div>
        
    )
}