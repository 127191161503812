import {BrowserRouter, Routes, Route} from 'react-router-dom';

import './styles/main.scss';

import Home from './pages/Home';
import Projects from './pages/Projects';
import About from './pages/About';
import NoPage from './pages/NoPage';

import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/Scroll';

export default function App() {
  return (
    <div>
      <BrowserRouter>
         <Header />
         <ScrollToTop />
         <main>
            <Routes>
               <Route path='/' element={<Home />} />
               <Route path='/projects' element={<Projects />} />
               <Route path='/about' element={<About />} />
               <Route path='*' element={<NoPage />} />
            </Routes>
         </main>
         <Footer />
    </BrowserRouter>
    </div>

  );
}

