
export default function TextBanner({text, title, subtext}) {
    return (
        <section className="presentation__section">
            <h4 className="sub__text">{subtext}</h4>
            <h1 className="presentation__title">{title}</h1>
            <h2 className="presentation__description">{text}</h2> 
        </section>

    )
}