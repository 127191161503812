import TextBanner from '../components/TextBanner';
import Skill from '../components/Skill';
import Contact from '../components/Contact';
import skillsList from '../data/skills';




export default function About() {

    const skillsSection = skillsList.map((item, index) => {
        return (
            <Skill 
                  key = {item.id}
                  title = {item.title}
                  imgUrl={item.imgUrl}
                  description = {Array.isArray(item.description) ? item.description : [item.description]} 
            />
        )
    })
    return (
        <section className='about__page'>
            <TextBanner title='A propos'
              text={`
              Je conçois des sites de l'idée initiale jusqu’à leur mise en ligne. 
              J'accorde une grande importance à l'esthétique et à l'expérience utilisateur, 
              en créant des interfaces responsives et accessibles pour tous·tes. 
              Ma créativité me permet de lier design et technologie pour concevoir 
              des sites où l'expérience utilisateur est au cœur de chaque projet.`}
              subtext='Présentation'/>
            <section className='skills__section'>
            <TextBanner title='Compétences' subtext='Mes softskills' />
              <div className='skills'>
              {skillsSection}
              </div>
            </section>
            <Contact />
        </section>
        
    )
}