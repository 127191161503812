import { Link } from 'react-router-dom';
import TextBanner from '../components/TextBanner'
import Contact from '../components/Contact';

 
export default function Home() {


    return (
    <div className='home__page'>
        <TextBanner title='Bienvenue sur mon portfolio'
              text={`Ce site est une vitrine de mon parcours et de mon évolution, 
              illustrant mes compétences et mon engagement pour des concepts efficaces, 
              interactifs et harmonieux! 
              Ici, je combine créativité et code pour créer des sites web
              à la fois intuitifs et visuellement captivants.
              J'y présente les projets sur lesquels j’ai travaillé, illustrant mon parcours,
              mes compétences techniques et mes choix créatifs.`}
              subtext='' /> 
        <Link className='link__btn' to='/projects'>
        <button className="button" >
           <p className='button__text'>Voir mes projets</p>
           <i className="icon fa fa-solid fa-magnifying-glass"></i>            
        </button>
        </Link>
        <section id='contact'>
          <Contact /> 
        </section>
    </div>
        
    )
}