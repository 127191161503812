import { useEffect } from "react";

export default function Modal({isOpen, onClose, project}) {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto'; 
        };
    }, [isOpen]);

    if(!isOpen || !project) return null;

    return (
        <aside className="modal__overlay" role="dialog" aria-labelledby="project" onClick={onClose}>
            <div className='modal__content' onClick={(e) => e.stopPropagation()}>
                <span className="close__btns">
                  <button className="close__btn" onClick={onClose}>
                      <i className="fa fa-solid fa-rotate-left"/>
                      <p>Revenir aux projets</p>
                  </button>
                  <button className="close__btn" onClick={onClose}>
                     <i className="fa-solid fa-circle-xmark"></i>
                  </button>
                </span>
                <h1 className="modal__title title">{project.title}</h1>
                <img src={project.cover} alt={project.title} className="modal__img" loading="lazy" />
                <h2 className="title">A propos</h2>
                <p className="modal__description">{project.description}</p>
                <h2 className="title">Difficultés rencontrées</h2>
                <p className="modal__description">{project.difficulties}</p>
                <h2 className="modal__technologies title">Technologies</h2>
                <span className="modal__tags">
                    {project.tags.map((tag, index) => (
                    <button className="tag" key={index}>{tag}</button> ))}
                </span>
               <span className="modal__github title">
                  <i className=" fa fa-brands fa-github"></i>
                  <h2 className="modal__share "> Github</h2>
                </span>
                <a href={project.github} target="_blank" className="modal__link"> {project.github} </a>
            </div>
        </aside>
    )
}