import TextBanner from './TextBanner';

export default function Contact () {
    return (
        <section  id='contact'>
            <TextBanner title='Contact'
            subtext='Mes informations' />
          <div className='contact__infos'>
            <button className='contact__btn'>
               <i className=" fa fa-solid fa-at"></i>
               <a className='mail__link link' href="mailto:kim9truong@gmail.com">kim9truong@gmail.com</a>
            </button>
            <button className='contact__btn'>
              <i className=" fa fa-brands fa-github"></i>
              <a className='github__info link' href='https://github.com/kimtr9' target='_blank'>https://github.com/kimtr9</a>
            </button>
          </div>
        </section>
        
    )
}