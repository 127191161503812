import { useState } from 'react';

import TextBanner from "../components/TextBanner";
import Card from '../components/Card';
import Filter from "../components/Filter";
import Modal from "../components/Modal";
import Contact from '../components/Contact';


import projectsList from '../data/projects';


export default function Projects() {

    //Gestion apparition modale
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProj, setSelectedProj] = useState(null)

    const openModal = (project) => {
        setSelectedProj(project);
        setIsModalOpen(true);
    }
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedProj(null);
    }

    //filtrage des projets
    const [selectedCat, setSelectedCat] = useState('Tous');
    const filteredProjects = selectedCat === 'Tous' ? projectsList 
    : projectsList.filter(project =>  project.category === selectedCat);

    const cards = filteredProjects.map(project => {
        return (
            <Card key= {project.id}
                  project={project}
                  id= {project.id}
                  openModal={openModal}/>
        )
    })

    return (
        <section className='projects__page' >
            <TextBanner 
              title='Mes Projets'
              text= {`Vous trouverez ici quelques projets sur lesquels j'ai pu travailler. 
              Chacun d'entre eux incarne une étape importante de mon apprentissage, ils 
              sont toujours une occasion d’explorer de nouvelles idées et d’affiner mon compétences
              Que ce soit pour des applications web interactives, des interfaces modernes ou des solutions
              pratiques, ces projets reflètent mon souci du détail et mon envie de toujours apprendre! 
              Parcourez-les pour découvrir mon univers! :)`}
              subtext='Mon portfolio' />
              <div className="filters">
                <Filter onCatSelect={setSelectedCat}/>
              </div>
            <section className='cards__section'>
                {cards}
            </section>
            <Modal isOpen={isModalOpen} onClose={closeModal} project={selectedProj} />
            <section id='contact'>
              <Contact />
            </section>


        </section>
    )
}