import { NavLink } from 'react-router-dom';

export default function Header() {
    return (
        <header className="header">
         <i className=" icon fa-solid fa-bahai"></i>   
         <a href="/" className="name__logo">
                Kim Truong
                </a>
            <nav className="navbar">
                <ul className='nav__links'>
                   <li> 
                       <NavLink to="/projects" className={({isActive}) => (isActive ? "nav__link--active" : "nav__link")}>
                        Projets
                       </NavLink>
                    </li>
                    <li>
                       <NavLink to="/about" className={({isActive}) => (isActive ? "nav__link--active" : "nav__link")}>
                        A propos
                       </NavLink>
                    </li>
                    <li>
                       <a href='#contact'  className="nav__link">
                        Contact
                       </a>
                    </li>
                </ul>
            </nav>
        </header>
    )
}